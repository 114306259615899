import { isRecord } from '../../shared/helpers/is-record/is-record.helper';
import { isString } from '../../shared/helpers/is-string/is-string.helper';

export const UNKNOWN_ERROR_MESSAGE = 'Unknown error';

export function getErrorMessage(error: unknown): string {
  if (!isRecord(error)) {
    return UNKNOWN_ERROR_MESSAGE;
  } else if (isString(error['message'])) {
    return error['message'];
  } else if (isString(error['name'])) {
    return error['name'];
  } else {
    return UNKNOWN_ERROR_MESSAGE;
  }
}
